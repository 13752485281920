import moment from 'moment';
import * as L from './Lang';
/*moment: https://momentjs.com/docs/#/displaying/ */

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

let _moment = moment('2017-01-01');

function d2s(date, format = 'YYYY-MM-DD') {
  if (date) {
    // change to local timezone display
    return moment(date)
      .local()
      .format(format);
  }
  return null;
}

function s2d(str) {
  if (str) {
    try {
      return moment(str).toDate();
    } catch (ex) {
      // bypass
    }
  }

  return null;
}

function formatDateStr(str, format = 'YYYY/MM/DD HH:mm') {
  try {
    return moment(str).format(format);
  } catch (err) {
    return null;
  }
}

const getDateString = date => {
  return date ? formatDateStr(date) : L.d({zh: '尚無紀錄', en: 'No Record'});
};

const dateDiffNow = (str, unit) => {
  const now = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
  const targetDate = moment(str, 'YYYY-MM-DD hh:mm:ss');
  return Math.abs(now.diff(targetDate, unit));
};

const reCheckoutInfo = orderDateStr => {
  const now = moment(new Date());
  const orderMoment = moment(orderDateStr);
  const tomorrowOfOrderMoment = moment(orderDateStr).add(1, 'days');
  const theDayAfterTomorrowOfOrderMoment = moment(orderDateStr).add(2, 'days');

  const orderDate = orderMoment.format('YYYY/MM/DD');
  const tomorrowOfOrderDate = tomorrowOfOrderMoment.format('YYYY/MM/DD');
  const theDayAfterTomorrowOfOrderDate = theDayAfterTomorrowOfOrderMoment.format(
    'YYYY/MM/DD',
  );

  const isOrderBeforeTenOClock = orderMoment.isBefore(
    moment(`${orderDate} 22:00:00`),
  );
  return {
    isCanReCheckout: isOrderBeforeTenOClock
      ? now.isBefore(moment(`${tomorrowOfOrderDate} 00:00:00`))
      : now.isBefore(moment(`${theDayAfterTomorrowOfOrderDate} 00:00:00`)),
    expiredDate: isOrderBeforeTenOClock ? orderDate : tomorrowOfOrderDate,
  };
};

export {
  formatDateStr,
  d2s,
  s2d,
  dateDiffNow,
  reCheckoutInfo,
  delay,
  getDateString,
};
