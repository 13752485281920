import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  Color,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FontSize,
  fstyles,
  UnderlineButton,
} from '../Components/Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import {
  getBirthdateValidator,
  getEmailValidator,
  getPasswordValidator,
  getPhoneValidator,
  getRequiredValidator,
  validateForm,
} from '../Utils/validateUtil';
import {
  Actions,
  Breakpoints,
  Context,
  NavActions,
} from '../Contexts/AppContext';
import {ChevronLeft} from '../Components/SvgIcon';
import {ForgotPassword} from '../Components/ForgotPasswordFlow';
import {useDimension} from '../Hooks/AppHooks';
import useCountDownTimer from '../Hooks/useCountDownTimer';
import qs from 'querystring';
import CustomNav from '../Components/CustomNav';
import {delay} from '../Utils/DateUtil';
import Check from '../images/checkbox-selected-checked.svg';
import TextInput from '../Components/TextInput';
import {getFormatCartItems} from '../Utils/ProductUtil';
import Alert from '../Components/Modal/Alert';
import {
  PrivacyPolicySections,
  TermsOfOnlineTransactionSections,
} from '../Domain/Help';
import moment from 'moment';

const initRegisteredValues = {
  username: '',
  birthdate: '',
  email: '',
  password: '',
};

const Container = styled.div`
  padding-top: var(--navbar-height);
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    padding-top: 30px;
  }
`;
const Wrapper = styled.div`
  width: 658px;
  margin: 48px auto 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Color.mainDark_10};
  padding: 40px 50px;
  box-sizing: border-box;
  & .error {
    color: #e22828;
    ${fstyles.text12}
  }
  & .button {
    & :hover {
      cursor: pointer;
    }
  }
  & * {
    user-select: initial;
  }
  @media screen and (max-width: ${Breakpoints.mobile}px) {
    width: 320px;
    border: none;
    padding: 20px;
    margin: 0 auto;
  }
`;
const Title = styled.div`
  ${fstyles.text24}
  color:${Color.mainDark};
  font-weight: bold;
  @media screen and (max-width: ${Breakpoints.mobile}px) {
      display: none;
  }
`;
const SubTitle = styled.div`
  ${fstyles.text20}
  color:${Color.mainDark};
  font-weight: bold;
`;
const ChangePhoneWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: ${Color.mainDark_03};
  margin-top: 32px;
  padding: 8px 20px;
  & .name {
    color: ${Color.mainDark_70};
    ${fstyles.text12};
  }
  & .value {
    color: ${Color.mainDark};
    ${fstyles.text14};
  }
`;
const Field = styled.div`
  width: 100%;
  border-bottom: 1px solid
    ${props => (props.error ? '#e22828' : Color.mainDark_10)};
  margin-top: 32px;
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  & label {
    color: ${Color.mainDark_70};
    ${fstyles.text12};
    visibility: hidden;
  }
  & input {
    outline: none;
    border: none;
    height: 61px;
    ${fstyles.text16}
  }
  & input:focus + label {
    visibility: visible;
  }
`;

const STEPS = {
  LONGIN_ENTRY: 'login_entry',
  REGISTERED: 'registered',
  NEW_USER_FIRST_STEP: 'new_user_first_step',
  NEW_USER_SECOND_STEP: 'new_user_second_step',
};

const LoginPage = props => {
  const {location} = props;
  const search = location.search.slice(1, location.search.length);
  const queryParams = qs.parse(search);
  const initPhone = queryParams.phone ?? '';
  const [step, setStep] = useState(STEPS.LONGIN_ENTRY);
  const [phone, setPhone] = useState(initPhone);
  const {mobile, tablet} = useDimension();
  const [values, setValues] = useState(initRegisteredValues);

  return (
    <Container>
      {(mobile || tablet) && (
        <CustomNav
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/profile');
              }}
            />
          }
          middle={<div>登入/註冊</div>}
        />
      )}
      {step === STEPS.LONGIN_ENTRY && (
        <LoginEntry
          step={step}
          setStep={setStep}
          phone={phone}
          setPhone={setPhone}
        />
      )}

      {step === STEPS.NEW_USER_FIRST_STEP && (
        <FirstStepOfNewUser
          phone={phone}
          setStep={setStep}
          isMobile={mobile}
          values={values}
          setValues={setValues}
        />
      )}

      {step === STEPS.NEW_USER_SECOND_STEP && (
        <SecondStepOfNewUser
          phone={phone}
          setStep={setStep}
          isMobile={mobile}
          prevPage={queryParams?.prevPage || null}
          values={values}
          setValues={setValues}
        />
      )}

      {step === STEPS.REGISTERED && (
        <StepOfRegistered
          phone={phone}
          setphone={setPhone}
          prevPage={queryParams?.prevPage || null}
        />
      )}
    </Container>
  );
};

export default LoginPage;

const LoginEntry = props => {
  const {setStep, phone, setPhone} = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [btnText, setBtnText] = useState(<div>下一步</div>);
  const submitRef = useRef(null);
  const phoneMaxLength = 10;

  const handleChange = value => {
    setPhone(value);
    if (value.length === phoneMaxLength) {
      handleSubmit(value);
    }
  };

  const onBlur = () => {
    setError(getErrorMsg(phone));
  };

  const getErrorMsg = value => getPhoneValidator().passRequest(value);

  const handleSubmit = async value => {
    try {
      setError(getErrorMsg(value));
      if (getErrorMsg(value)) {
        return;
      }

      setIsLoading(true);

      const _isExist = await Actions.getCustomerExist(value);

      setIsLoading(false);

      if (_isExist) {
        setBtnText(
          <div>
            <img src={Check} alt="" />
            <span>已註冊</span>
          </div>,
        );
        await delay(1000);
        setStep(STEPS.REGISTERED);
      } else {
        setBtnText(
          <div>
            <img src={Check} alt="" />
            <span>新用戶</span>
          </div>,
        );
        await delay(1000);
        setStep(STEPS.NEW_USER_FIRST_STEP);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Title>登入 / 註冊</Title>
      <TextInput
        label="手機號碼"
        value={phone}
        onChange={e => {
          handleChange(e.target.value);
        }}
        maxLength={phoneMaxLength}
        onBlur={onBlur}
        error={error}
        style={{marginTop: 20, marginBottom: 32}}
      />
      <RectButton
        skin={BUTTON_SKIN.PRIMARY}
        size={BUTTON_SIZE.LARGE}
        text={btnText}
        isDisabled={phone.length <= 0}
        style={{marginTop: '32px'}}
        onClick={() => handleSubmit(phone)}
        refer={submitRef}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

const FirstStepOfNewUser = props => {
  const {phone, setStep, values, setValues} = props;
  const maximumErrorCounts = 5;
  const [otp, setOtp] = useState('');
  const [isRegenOtpEnable, setIsRegenOtpEnable] = useState(false);
  const [error, setError] = useState('');
  const [errorCounts, setErrorCounts] = useState(0);
  const [seconds, setSeconds] = useCountDownTimer(() => {
    setIsRegenOtpEnable(true);
  });

  const genOtp = useCallback(async () => {
    await Actions.genOtp(phone);
  }, [phone]);

  useEffect(() => {
    genOtp();
  }, [genOtp]);

  const onSubmit = async value => {
    try {
      Actions.setLoading(true);
      const response = await Actions.validateOtp({phone: phone, otp: value});
      const {customer, success} = response;
      const errorMsg = success ? null : '驗證碼不正確';
      setError(errorMsg);
      if (errorMsg) {
        setErrorCounts(errorCounts + 1);
        return;
      }
      setValues(prev => ({
        ...prev,
        username: customer.name || '',
        birthdate: moment(customer.birthday).format('YYYY-MM-DD') || '',
        email: customer.email || '',
      }));
      setStep(STEPS.NEW_USER_SECOND_STEP);
    } finally {
      Actions.setLoading(false);
    }
  };

  const handleIdentifyCodeChange = e => {
    const {value} = e.target;
    setOtp(value);
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  const handleRegenIdentifyCodeClick = () => {
    genOtp();
    setIsRegenOtpEnable(false);
    setSeconds(60);
    setErrorCounts(0);
  };

  return (
    <Wrapper>
      <Title>登入 / 註冊</Title>
      <ChangePhoneWrapper>
        <FlexCol>
          <label className="name">手機號碼</label>
          <label className="value">{phone}</label>
        </FlexCol>
        <UnderlineButton
          onClick={() => {
            setStep(STEPS.LONGIN_ENTRY);
          }}>
          變更號碼
        </UnderlineButton>
      </ChangePhoneWrapper>
      <SubTitle style={{marginTop: '64px'}}>
        初次使用線上購物，請驗證手機
      </SubTitle>
      <TextInput
        label="6 位數字驗證碼"
        maxLength={6}
        inputMode="numeric"
        type="text"
        value={otp}
        name="otp"
        onChange={handleIdentifyCodeChange}
        error={error}
        style={{marginTop: 24, marginBottom: 40}}
      />
      <FlexRowCenter style={{marginTop: '20px'}}>
        {isRegenOtpEnable ? (
          <div
            onClick={handleRegenIdentifyCodeClick}
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            請點此重新發送驗證碼
          </div>
        ) : (
          <div
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            {errorCounts < maximumErrorCounts
              ? `重新發送驗證碼 (${seconds}s)`
              : `錯誤次數過多，請稍後再重新發送驗證碼 (${seconds}s)`}
          </div>
        )}
      </FlexRowCenter>
    </Wrapper>
  );
};

const SecondStepOfNewUser = props => {
  const {phone, setStep, isMobile, prevPage, values, setValues} = props;
  const [isLoading, setIsLoading] = useState(false);
  const app = useContext(Context);
  const [errors, setErrors] = useState(initRegisteredValues);

  const validators = useMemo(
    () => ({
      username: getRequiredValidator(),
      birthdate: getBirthdateValidator(),
      email: getEmailValidator(),
      password: getPasswordValidator(),
    }),
    [],
  );

  const handleChange = useCallback(
    e => {
      const {name, value} = e.target;
      const _values = {...values};
      _values[name] = value;
      setValues(_values);
    },
    [setValues, values],
  );

  const handleBlur = useCallback(
    e => {
      const {name, value} = e.target;
      const error = validators[name].passRequest(value);
      const _errors = {...errors};
      _errors[name] = error;
      setErrors(_errors);
    },
    [errors, validators],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const isAllPass = validateForm({values, errors, setErrors, validators});
      if (!isAllPass) {
        return;
      }
      setIsLoading(true);

      const resp = await Actions.register({
        ...values,
        birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
        phone: phone,
      });

      const {message, success} = resp;

      if (!success) {
        let msg = '';
        if (Array.isArray(message) && typeof message[0] === 'string') {
          msg = message[0];
        } else if (typeof message === 'string') {
          msg = message;
        } else {
          msg = JSON.stringify(message);
        }
        throw {
          err: 'register error',
          message: msg,
        };
      }
    } catch (err) {
      alert(err?.message || '註冊失敗');
      return;
    } finally {
      setIsLoading(false);
    }

    const token = await Actions.login({
      phone: phone,
      password: values.password,
    });
    await Actions.syncPos();

    setTimeout(async () => {
      await Actions.syncPos();
    }, 1000 * 60 * 60 * 8);

    if (app.cart.items.length > 0) {
      await Actions.updateCart({items: getFormatCartItems(app.cart.items)});
    } else {
      await Actions.fetchCart();
    }

    if (token) {
      if (prevPage) {
        NavActions.navigate(`/${prevPage}`);
      } else {
        NavActions.navigate('/home');
      }
    }

    setIsLoading(false);
  }, [app.cart.items, errors, phone, prevPage, validators, values]);

  return (
    <Wrapper>
      <Title>登入 / 註冊</Title>
      <ChangePhoneWrapper>
        <FlexCol>
          <label className="name">手機號碼</label>
          <label className="value">{phone}</label>
        </FlexCol>
        <UnderlineButton
          onClick={() => {
            setStep(STEPS.LONGIN_ENTRY);
          }}>
          變更號碼
        </UnderlineButton>
      </ChangePhoneWrapper>
      <SubTitle style={{marginTop: '64px'}}>
        請建立線上帳號，您將可以一同累計線上與門市的消費
      </SubTitle>

      {!isMobile && (
        <FlexRow
          style={{
            justifyContent: 'space-between',
            alignItems: 'baseline',
            margin: '24px 0',
          }}>
          <FlexCol style={{width: '48%'}}>
            <TextInput
              label="姓名"
              name="username"
              value={values.username}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.username}
            />
          </FlexCol>
          <FlexCol style={{width: '48%'}}>
            <TextInput
              label="出生年月日"
              name="birthdate"
              value={values.birthdate}
              type="date"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.birthdate}
            />
            <span
              style={{
                fontSize: FontSize.mini,
                lineHeight: `${FontSize.mini * 1.5}px`,
                margin: '4px 0',
              }}>
              您將獲得專屬生日禮
            </span>
          </FlexCol>
        </FlexRow>
      )}

      {isMobile && (
        <>
          <FlexCol>
            <TextInput
              label="姓名"
              name="username"
              value={values.username}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.username}
            />
          </FlexCol>
          <FlexCol>
            <TextInput
              label="出生年月日"
              name="birthdate"
              value={values.birthdate}
              type="date"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.birthdate}
            />
            <span
              style={{
                fontSize: FontSize.mini,
                lineHeight: `${FontSize.mini * 1.5}px`,
                margin: '4px 0',
              }}>
              您將獲得專屬生日禮
            </span>
          </FlexCol>
        </>
      )}

      <FlexCol>
        <TextInput
          label="電子信箱"
          name="email"
          inputMode="email"
          value={values.email}
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
          style={{marginBottom: 24}}
        />
      </FlexCol>

      <FlexCol style={{marginBottom: '32px'}}>
        <TextInput
          label="密碼 ( 8 - 12 位英數字 )"
          name="password"
          value={values.password}
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
        />
      </FlexCol>
      <RectButton
        skin={BUTTON_SKIN.PRIMARY}
        text="建立帳號"
        style={{marginBottom: '8px'}}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
      <span
        style={{
          fontSize: FontSize.mini,
          lineHeight: `${FontSize.mini * 1.5}px`,
          color: Color.mainDark_70,
          margin: '0 auto',
          textAlign: 'center',
        }}>
        點擊按鈕，即表示您同意我們的
        {isMobile && <br />}{' '}
        <UnderlineButton
          onClick={() => {
            Actions.setGlobalModalContent(
              <Alert icon={null} title="會員條款" cancelLabel="確認">
                <div style={{maxHeight: 400, overflowY: 'auto'}}>
                  {TermsOfOnlineTransactionSections.map((s, index) => (
                    <section key={index} className="section">
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          margin: '8px 0',
                        }}
                        className="title">
                        {s.title}
                      </div>
                      {s.data.map((c, idx) => (
                        <div key={idx} className="content">
                          {c}
                        </div>
                      ))}
                    </section>
                  ))}
                </div>
              </Alert>,
            );
          }}
          style={{
            fontSize: FontSize.mini,
            lineHeight: `${FontSize.mini * 1.5}px`,
            color: Color.mainDark_70,
            margin: '0 auto',
            textAlign: 'center',
          }}>
          會員條款
        </UnderlineButton>{' '}
        與{' '}
        <UnderlineButton
          onClick={() => {
            Actions.setGlobalModalContent(
              <Alert icon={null} title="隱私權政策" cancelLabel="確認">
                <div style={{maxHeight: 400, overflowY: 'auto'}}>
                  {PrivacyPolicySections.map((s, index) => (
                    <section key={index} className="section">
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          margin: '8px 0',
                        }}
                        className="title">
                        {s.title}
                      </div>
                      {s.data.map((c, idx) => (
                        <div key={idx} className="content">
                          {c}
                        </div>
                      ))}
                    </section>
                  ))}
                </div>
              </Alert>,
            );
          }}
          style={{
            fontSize: FontSize.mini,
            lineHeight: `${FontSize.mini * 1.5}px`,
            color: Color.mainDark_70,
            margin: '0 auto',
            textAlign: 'center',
          }}>
          隱私權政策
        </UnderlineButton>
      </span>
    </Wrapper>
  );
};

const StepOfRegistered = props => {
  const app = useContext(Context);
  const {phone, prevPage} = props;
  const {mobile, tablet} = useDimension();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    phone: phone,
    password: '',
  });
  const [errors, setErrors] = useState({
    phone: '',
    password: '',
  });
  const validators = useMemo(
    () => ({
      phone: getPhoneValidator(),
      password: getPasswordValidator(),
    }),
    [],
  );

  const handleChange = useCallback(
    e => {
      const {name, value} = e.target;
      setValues({...values, [name]: value});
    },
    [values],
  );

  const onBlur = field => {
    const error = validators[field].passRequest(values[field]);
    setErrors({...errors, [field]: error});
  };

  const handleSubmit = useCallback(async () => {
    const isAllPass = validateForm({values, errors, setErrors, validators});
    if (!isAllPass) {
      return;
    }

    try {
      setIsLoading(true);
      await Actions.login(values);
      await Actions.syncPos();
      setTimeout(async () => {
        await Actions.syncPos();
      }, 1000 * 60 * 60 * 8);

      if (app.cart.items.length > 0) {
        await Actions.updateCart({items: getFormatCartItems(app.cart.items)});
      } else {
        await Actions.fetchCart();
      }
      await Actions.fetchMe();

      if (prevPage) {
        NavActions.navigate(`/${prevPage}`);
      } else {
        NavActions.navigate('/home');
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrors({...errors, password: '密碼不正確'});
    }
  }, [app.cart.items, errors, prevPage, validators, values]);

  return (
    <Wrapper>
      <div style={{fontSize: mobile || tablet ? 20 : 24, fontWeight: 'bold'}}>
        TUN 會員您好，歡迎回來
      </div>
      <TextInput
        label="手機號碼"
        name="phone"
        value={values.phone}
        onChange={handleChange}
        maxLength={10}
        onBlur={() => {
          onBlur('phone');
        }}
        error={errors.phone}
        style={{marginTop: 24, marginBottom: 24}}
      />
      <TextInput
        label="密碼"
        type="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={() => {
          onBlur('password');
        }}
        error={errors.password}
        style={{marginBottom: 32}}
      />

      <RectButton
        skin={BUTTON_SKIN.PRIMARY}
        size={BUTTON_SIZE.LARGE}
        text="登入"
        isDisabled={values.phone.length <= 0}
        style={{margin: '32px 0'}}
        isLoading={isLoading}
        onClick={handleSubmit}
      />
      <FlexRowCenter>
        <UnderlineButton
          onClick={() => {
            Actions.setGlobalModalContent(<ForgotPassword phone={phone} />);
          }}>
          忘記密碼
        </UnderlineButton>
      </FlexRowCenter>
    </Wrapper>
  );
};
