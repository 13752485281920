import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {ChevronLeft, Cross, Eye, EyeSlash} from './SvgIcon';
import {Actions, Context, NavActions} from '../Contexts/AppContext';
import {
  Color,
  FlexCol,
  FlexColCenter,
  FlexRow,
  FlexRowCenter,
  FontSize,
  fstyles,
  UnderlineButton,
} from './Widget';
import {
  getForgotPasswordValidator,
  getPhoneValidator,
} from '../Utils/validateUtil';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import styled from 'styled-components';
import useCountDownTimer from '../Hooks/useCountDownTimer';
import Lock from '../images/illustration-password.svg';
import {useDimension} from '../Hooks/AppHooks';
import TextInput from './TextInput';
import {getFormatCartItems} from '../Utils/ProductUtil';

const Wrapper = styled.div`
  position: relative;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? '100%' : '430px')};
  background-color: #fff;
  & .title {
    color: ${Color.mainDark};
    font-weight: bold;
    ${fstyles.text20};
  }
  & .subTitle {
    color: ${Color.mainDark_70};
    ${fstyles.text14}
  }
  & .button {
    & :hover {
      cursor: pointer;
    }
  }
  & .error {
    color: #e22828;
    ${fstyles.text12}
  }
`;

const Field = styled.div`
  width: 100%;
  border-bottom: 1px solid
    ${props => (props.error ? '#e22828' : Color.mainDark_10)};
  margin-top: 32px;
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  & label {
    color: ${Color.mainDark_70};
    ${fstyles.text12};
    visibility: hidden;
  }
  & input {
    outline: none;
    border: none;
    height: 61px;
    ${fstyles.text16}
  }
  & input:focus + label {
    visibility: visible;
  }
`;

const FORGOT_PASSWORD_STEPS = {
  FIRST_STEP: 'first',
  SECOND_STEP: 'second',
  THIRD_STEP: 'third',
  FORTH_STEP: 'forth',
};

export const ForgotPassword = props => {
  const {phone} = props;
  const {mobile, tablet} = useDimension();
  const [value, setValue] = useState(phone);
  const [step, setStep] = useState(FORGOT_PASSWORD_STEPS.FIRST_STEP);
  const [otp, setOtp] = useState('');

  return (
    <Wrapper smallDevice={mobile || tablet}>
      {step === FORGOT_PASSWORD_STEPS.SECOND_STEP && (
        <ChevronLeft
          className="button"
          style={{position: 'absolute', top: '20px', left: '20px'}}
          onClick={() => {
            let curIndex = Object.values(FORGOT_PASSWORD_STEPS).findIndex(
              s => s === step,
            );
            const prevStep = Object.values(FORGOT_PASSWORD_STEPS)[curIndex - 1];
            setStep(prevStep);
          }}
        />
      )}

      <Cross
        onClick={() => {
          Actions.setGlobalModalContent(null);
        }}
        className="button"
        style={{position: 'absolute', top: '20px', right: '20px'}}
      />

      {step === FORGOT_PASSWORD_STEPS.FIRST_STEP && (
        <FirstStepOfForgotPassword
          phone={value}
          setPhone={setValue}
          setStep={setStep}
        />
      )}

      {step === FORGOT_PASSWORD_STEPS.SECOND_STEP && (
        <SecondStepOfForgotPassword
          otp={otp}
          setOtp={setOtp}
          phone={value}
          setStep={setStep}
        />
      )}

      {step === FORGOT_PASSWORD_STEPS.THIRD_STEP && (
        <ThirdStepOfForgotPassword otp={otp} phone={value} setStep={setStep} />
      )}

      {step === FORGOT_PASSWORD_STEPS.FORTH_STEP && (
        <ForthStepOfForgotPassword />
      )}
    </Wrapper>
  );
};
const FirstStepOfForgotPassword = props => {
  const {phone, setPhone, setStep} = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (error) {
      return;
    }

    setIsLoading(true);
    const isExist = await Actions.getCustomerExist(phone);
    setIsLoading(false);

    if (isExist) {
      await Actions.genOtp(phone);
      setStep(FORGOT_PASSWORD_STEPS.SECOND_STEP);
    } else {
      setError('此手機號碼尚未註冊');
    }
  };

  return (
    <>
      <FlexColCenter>
        <div className="title">忘記密碼？</div>
        <div className="subTitle">請輸入您的手機，我們會寄送密碼重置簡訊</div>
      </FlexColCenter>

      <TextInput
        label="手機號碼"
        maxLength={10}
        name="phone"
        id="phone"
        type="text"
        value={phone}
        onChange={e => {
          setPhone(e.target.value);
        }}
        onBlur={e => {
          setError(getPhoneValidator().passRequest(e.target.value));
        }}
        error={error}
        style={{margin: '24px 0'}}
      />

      <RectButton
        skin={BUTTON_SKIN.PRIMARY}
        size={BUTTON_SIZE.LARGE}
        text={'發送簡訊'}
        style={{marginTop: '32px', width: '100%'}}
        isDisabled={phone.length <= 0}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

const SecondStepOfForgotPassword = props => {
  const {phone, setStep, otp, setOtp} = props;
  const maximumErrorCounts = 5;
  const [error, setError] = useState('');
  const [isRegenOtpEnable, setIsRegenOtpEnable] = useState(false);
  const [errorCounts, setErrorCounts] = useState(0);
  const [seconds, setSeconds] = useCountDownTimer(() => {
    setIsRegenOtpEnable(true);
  });

  const handleRegenOtpClick = async () => {
    await Actions.genOtp(phone);
    setIsRegenOtpEnable(false);
    setSeconds(60);
    setErrorCounts(0);
  };

  const handleChange = e => {
    const {value} = e.target;
    setOtp(value);
    if (value.length === 6) {
      autoSubmit(value);
    }
  };

  const autoSubmit = async value => {
    const res = await Actions.validateOtp({phone: phone, otp: value});
    const {success} = res;
    const errorMsg = success ? null : '驗證碼不正確';
    setError(errorMsg);
    if (errorMsg) {
      setErrorCounts(errorCounts + 1);
      return;
    }
    setStep(FORGOT_PASSWORD_STEPS.THIRD_STEP);
  };

  const getEncryptPhone = useCallback(() => {
    const sliceOne = phone.slice(0, 4);
    const sliceTwo = phone.slice(7, 10);
    return sliceOne + ' *** ' + sliceTwo;
  }, [phone]);

  return (
    <>
      <FlexColCenter>
        <div className="title">請輸入簡訊驗證碼</div>
        <div className="subTitle">驗證碼已發送至 {getEncryptPhone()}</div>
      </FlexColCenter>
      <TextInput
        label="6 位數字驗證碼"
        value={otp}
        onChange={handleChange}
        type="numeric"
        inputMode="numeric"
        error={error}
        maxLength={6}
        style={{margin: '24px 0'}}
      />
      <FlexRowCenter style={{marginTop: '20px'}}>
        {isRegenOtpEnable ? (
          <div
            onClick={handleRegenOtpClick}
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            請點此重新發送驗證碼
          </div>
        ) : (
          <div
            style={{
              textDecoration: 'underline',
              color:
                errorCounts < maximumErrorCounts ? Color.mainDark : '#e22828',
            }}>
            {errorCounts < maximumErrorCounts
              ? `重新發送驗證碼 (${seconds}s)`
              : `錯誤次數過多，請稍後再重新發送驗證碼 (${seconds}s)`}
          </div>
        )}
      </FlexRowCenter>
    </>
  );
};

const ThirdStepOfForgotPassword = props => {
  const {setStep, phone, otp} = props;
  const [loading, setLoading] = useState(false);
  const app = useContext(Context);
  const initialState = useMemo(
    () => ({
      password: '',
      confirm: '',
    }),
    [],
  );
  const [errors, setErrors] = useState(initialState);
  const [values, setValues] = useState(initialState);

  const handleChange = useCallback(
    e => {
      const {name, value} = e.target;
      let _nextValue = value;
      let _prevValue = values[name];
      if (name === 'password' || name === 'confirm') {
        if (value.length > 12) {
          _nextValue = _prevValue;
        }
        if (value.length - _prevValue.length > 1) {
          // disallow user to paste in
          _nextValue = _prevValue;
        }
      }

      setValues({...values, [name]: _nextValue});
    },
    [values],
  );

  const validators = useMemo(
    () => ({
      password: value => {
        return getForgotPasswordValidator().passRequest(value);
      },
      confirm: value => {
        if (value === values.password) {
          return null;
        } else {
          return '輸入密碼不相同';
        }
      },
    }),
    [values.password],
  );

  const handleBlur = useCallback(
    e => {
      const {name, value} = e.target;
      setErrors({...errors, [name]: validators[name](value)});
    },
    [errors, validators],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const _errors = {...errors};
      Object.keys(validators).forEach(
        field => (_errors[field] = validators[field](values[field])),
      );
      setErrors(_errors);
      const isAllPass = Object.values(_errors).every(error => error === null);
      if (!isAllPass) {
        return;
      }

      const resp = await Actions.resetPassword({
        phone,
        otp,
        password: values.password,
      });

      if (!resp.success) {
        alert(JSON.stringify(resp.message));
        return;
      }

      try {
        const token = await Actions.login({
          phone: phone,
          password: values.password,
        });
        await Actions.syncPos();

        setTimeout(async () => {
          await Actions.syncPos();
        }, 1000 * 60 * 60 * 8);

        if (app.cart.items.length > 0) {
          await Actions.updateCart({items: getFormatCartItems(app.cart.items)});
        } else {
          await Actions.fetchCart();
        }
      } catch (e) {
        alert('嘗試登入失敗，請使用新密碼登入。');
      }

      setStep(FORGOT_PASSWORD_STEPS.FORTH_STEP);
    } catch (e) {
      alert('重設密碼失敗，請稍後再嘗試。');
    } finally {
      setLoading(false);
    }
  }, [app.cart.items, errors, otp, phone, setStep, validators, values]);

  return (
    <>
      <FlexColCenter>
        <div className="title">請輸入新密碼</div>
        <FlexCol style={{width: '100%'}}>
          <TextInput
            label="密碼 ( 8 - 12 位英數字 )"
            name="password"
            value={values.password}
            error={errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={e => {
              e.preventDefault();
            }}
            type="password"
            style={{margin: '24px 0'}}
          />
        </FlexCol>

        <FlexCol style={{width: '100%'}}>
          <TextInput
            label="確認密碼"
            name="confirm"
            value={values.confirm}
            error={errors.confirm}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={e => {
              e.preventDefault();
            }}
            type="password"
            style={{margin: '24px 0'}}
          />
        </FlexCol>
        <RectButton
          isLoading={loading}
          skin={BUTTON_SKIN.PRIMARY}
          text="確定"
          style={{marginTop: '32px', width: '100%'}}
          onClick={handleSubmit}
          isDisabled={errors.password !== null || values.confirm === ''}
        />
      </FlexColCenter>
    </>
  );
};

const ForthStepOfForgotPassword = () => {
  return (
    <FlexColCenter>
      <img src={Lock} alt="" />
      <FlexColCenter>
        <div className="title" style={{marginBottom: 4}}>
          密碼已更新
        </div>
        <div className="subTitle" style={{marginBottom: 40}}>
          之後請使用新密碼登入
        </div>
      </FlexColCenter>
      <RectButton
        text="確定"
        skin={BUTTON_SKIN.PRIMARY}
        onClick={() => {
          Actions.setGlobalModalContent(null);
          NavActions.navigate('/home');
        }}
        style={{width: '100%'}}
      />
    </FlexColCenter>
  );
};
