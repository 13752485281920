import {useEffect, useState} from 'react';

const useCountDownTimer = timesUpCallBack => {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let timeoutId;

    if (seconds > 0) {
      timeoutId = setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    if (seconds <= 0) {
      timesUpCallBack();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [seconds, timesUpCallBack]);

  return [seconds, setSeconds];
};

export default useCountDownTimer;
